<template>
  <div id="app-base-file">
    <div class="header">
        <div class="header-action">
          <div class="min-box" v-if="this.$ipc">
            <div class="min" @click="min(1)" ></div>
          </div>
          <div class="min-box" v-if="this.$ipc">
            <div class="max" @click="min(3)" ></div>
          </div>
          <div class="min-box-right" v-if="this.$ipc"> 
            <div class="close-right" @click="min(2)" ></div>
          </div>
        </div>
        <div class="title">
          <div class="title-text">{{ fromName }} <img v-show="fromNameZ" width="15px" height="15px" src="@/assets/zicon.png"/></div>
          <div class="more" @click="cheoosInfos"></div>  
        </div>
      </div>
    <div style="height: calc(100% - 75px); width: 100%; display: flex;">
      <div style="height:100%; width: 100%;position: relative;">
        <!-- <div class="message" id="message" ref="mianscroll" @scroll="orderscrollEvent"> -->
        <div class="message" id="message" ref="mianscroll">
        <div class="loadMore" :class="loading? '': 'loadMoreh'" @click="loadMore">
        {{loading? '加载中...':'点击加载更多'}}
        </div>
        <div class="message-box" v-for="(item,index) in MessageList" :key="item.id">
          <div class="outer" v-if="!item.btype">
            <div class="message-body">
              <div class="user-header">
                <img :src="item.avatarurl">
              </div>
              <div class="text-plant">
                <div class="text-name">{{item.name}}</div>
                <div class="text-body" v-if="item.type == 'txt' && item.msg.indexOf('[') >= 0 && getTypes(item) == 'false'">
                  <div v-html="getEmimg(item)"></div>
                </div>
                <div class="text-body" v-if="item.type == 'txt' && item.msg.indexOf('[') < 0 && getTypes(item) == 'false' ">{{ item.msg }}</div>
                <!-- <div class="text-body" v-if="item.type == 'img'">
                  <img class="image-box" :src="item.url" @click="preImg(item.url)">
                  <div class="file_download" style="color: #FE5D42; margin-left: 10px;cursor: pointer; margin-top: 10px;"  v-if="item.localurl" @click="openDir(item.ext.filename)">打开文件路径</div>
                </div> -->
                <div class="text-body" v-if="item.type == 'img'">
                  <!-- <img class="image-box" :src="item.url" @click="preImg(item.url)"> -->
                  <previewImage class="image-box" :src="item.url"/>
                  <div class="file_download" style="color: #FE5D42; margin-left: 10px;cursor: pointer; margin-top: 10px;"  v-if="item.localurl" @click="openDir(item.ext.filename)">打开文件路径</div>
                  <a class="file_download" :href="item.url" v-if="!item.localurl" download>下载文件</a>
                </div>
                <div class="text-body file-box" v-if="item.type == 'file'">
                  <div><img class="image-box-file" :src="getimage(item.filename)"></div>
                  <div style="margin-left: 125px;">
                    <div>{{ item.filename }}</div>
                    <div style="color:#999999">{{ fileSizeFormat(item.file_length) }}</div>
                    <a class="file_download" :href="item.url" v-if="!item.localurl" download>下载文件</a>
                    <a class="file_download"  v-if="item.localurl" @click="openDir(item.filename)">打开文件路径</a>
                  </div>
                </div>
                <div class="text-body" v-if="item.type == 'audio'"   style="display: flex;">
                  <img class="image-box-audio" style="margin-right: 10px;cursor: pointer;" src="@/assets/audio.png" @click="startplayAudio(item,index)" >
                  <div class="audio-text" v-if="item.type == 'audio'">{{item.length}}′′</div>
                  <img class="image-box-audio" style="cursor: pointer; margin-left: 120px; width: 18px;" src="@/assets/wenzi.png" @click="redAudio(item,index)">
                </div>
                <div class="text-body"  v-if="item.type == 'video'" >
                  <video :src="item.url" style="width: 200px;" controls="controls"></video>
                  <div class="file_download" style="color: #FE5D42; margin-left: 10px;cursor: pointer; margin-top: 10px;"  @click="openDir(item.filename)">打开文件路径</div>
                </div>
                <div class="text-body" v-if="item.type == 'txt' && getTypes(item) == 'goods' ">
                  <div>点击查看详情</div>
                  <div class="custom-body">
                    <div class="custom-img">
                      <img :src="item.newmessage.image"/>
                    </div>
                    <div class="custom-text">{{item.newmessage.title}}</div>
                  </div>
                  <div class="custom-action">
                    <a-button type="primary" class="custom-ok" @click="preInfos(item)">查看详情</a-button>
                  </div>
                </div>
                <div class="text-body" style="color:blue;" v-if="item.type == 'txt' && getTypes(item) == 'http' ">
                  {{ item.msg }}
                </div>
                <div class="text-body" v-if="item.type == 'custom'">
               <!-- 设计订单消息 designerOrder -->
               <div  v-if="item.customExts.type == 'renderProduct'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" class="custom-ok" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>
                  <div  v-if="item.customExts.type == 'designerProduct'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                        <div class="custom-price">¥<span style="font-size:14px;">{{fen2yuan(item.customExts.price)}}</span></div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" class="custom-ok" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>
                  <div  v-if="item.customExts.type == 'designerOrder'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                        <div class="custom-price">¥<span style="font-size:14px;">{{fen2yuan(item.customExts.price)}}</span></div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" :class="item.customExts.actionStatus?'custom-ok':'custom-ok-cleal'" :disabled="item.customExts.actionStatus" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>

                  <div  v-if="item.customExts.type == 'designerChangeOrder'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                        <div class="custom-price">{{fen2yuan(item.customExts.price)>0?'¥':''}}<span style="font-size:14px;">{{fen2yuan(item.customExts.price)>0?fen2yuan(item.customExts.price):'免费改图'}}</span></div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" :class="item.customExts.actionStatus?'custom-ok':'custom-ok-cleal'"  @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>

                  <div  v-if="item.customExts.type == 'designerFixOrder'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                        <div class="custom-price">{{fen2yuan(item.customExts.price)>0?'¥':''}}<span style="font-size:14px;">{{fen2yuan(item.customExts.price)>0?fen2yuan(item.customExts.price):'免费改图'}}</span></div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" :class="item.customExts.actionStatus?'custom-ok':'custom-ok-cleal'" :disabled="item.customExts.actionStatus" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>

                </div>
               
              </div>
              <div class="red2"></div>
            </div>
            <div class="text-body" v-if="item.type == 'audio' && item.redfy == 1" style="margin-left:40px; max-width: 200px;text-align: left;padding-bottom: 2px;float:left;" >
                  <div>{{item.redtext}}</div>
                  <div style="width: 100%; text-align: center;cursor: pointer;" @click="coloseRed(item)"><img style="width: 20px;"  src="@/assets/upred.png"  ></div>
            </div>
            <div class="message-time" style="clear: both; padding-top: 6px;">{{ item.time }}</div>
          </div>
          <div class="my" v-if="item.btype">
            <div class="message-body-my">
              <div :class="item.deliver ? 'red2' : 'red'"></div>
           
              <div class="text-plant">
                <div class="text-name-my">{{item.name}}</div>
                
                <div class="text-body-my" v-if="item.type == 'txt' && item.msg.indexOf('[') >= 0 && getTypes(item) == 'false'">
                  <div v-html="getEmimg(item)"></div>
                </div>
                <div class="text-body-my" v-if="item.type == 'txt' && item.msg.indexOf('[') < 0 && getTypes(item) == 'false'">{{ item.msg }}</div>
                <div class="text-body-my" v-if="item.type == 'img'">
                  <!-- <img class="image-box" :src="item.url" @click="preImg(item.url)"> -->
                  <previewImage class="image-box" :src="item.url"/>
                  <a class="file_download" :href="item.url" download>下载文件</a>
                  <div class="file_download" style="color: #FE5D42; margin-left: 10px;cursor: pointer; margin-top: 10px;"  v-if="item.localurl" @click="openDir(item.ext.filename)">打开文件路径</div>
                </div>
                <div class="text-body-my file-box" v-if="item.type == 'file'">
                  <div>
                    <div>{{ item.filename }}</div>
                    <div style="color:#999999">{{ fileSizeFormat(item.file_length) }}</div>
                    <a class="file_download" :href="item.url" v-if="!item.localurl" download>下载文件</a>
                    <a class="file_download"  v-if="item.localurl" @click="openDir(item.filename)">打开文件路径</a>
                  </div>
                  <div style="margin-left: 125px;"><img class="image-box-file" :src="getimage(item.filename)"></div>
                </div>
                
                <div class="text-body-my" v-if="item.type == 'audio'" style="display: flex;" >
                  <img class="image-box-audio" style="cursor: pointer; margin-right: 120px; width: 18px;" src="@/assets/wenzi.png" @click="redAudio(item,index)">
                  <img class="image-box-audio" src="@/assets/audio.png" style="cursor: pointer;" @click="startplayAudio(item,index)">
                  <div class="audio-text-my" v-if="item.type == 'audio'">{{item.length}}′′</div>
                </div>
                
                <div class="text-body-my" v-if="item.type == 'video'" >
                  <video :src="item.url" style="width: 200px;" controls="controls"></video>
                  <div class="file_download" style="color: #FE5D42; margin-left: 10px;cursor: pointer; margin-top: 10px;"  @click="openDir(item.filename)">打开文件路径</div>
                </div>
                <div class="text-body-my" v-if="item.type == 'txt' && getTypes(item) == 'goods' ">
                  <div>点击查看详情</div>
                  <div class="custom-body">
                    <div class="custom-img">
                      <img :src="item.newmessage.image"/>
                    </div>
                    <div class="custom-text">{{getStringExt(item.newmessage.title)}}</div>
                  </div>
                  <div class="custom-action">
                    <a-button type="default" class="custom-ok-cleal" @click="preInfos(item)">查看详情</a-button>
                  </div>
                </div>
                <div class="text-body-my" style="color:blue;" v-if="item.type == 'txt' && getTypes(item) == 'http' ">
                  {{ item.msg }}
                </div>
                <div class="text-body-my" v-if="item.type == 'custom'">
                  <!-- 设计订单消息 designerOrder -->
                  
                  <div  v-if="item.customExts.type == 'selectOrder'">
                    <div>已帮您选择好商品规格，立即下单</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                  
                      </div>
                    </div>
                    <!-- <div class="custom-action">
                      <a-button type="default" class="custom-ok" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div> -->
                  </div>
                  <div  v-if="item.customExts.type == 'renderProduct'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" class="custom-ok" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>
                  <div  v-if="item.customExts.type == 'designerProduct'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                        <div class="custom-price">¥<span style="font-size:14px;">{{fen2yuan(item.customExts.price)}}</span></div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" class="custom-ok" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>
                  <div  v-if="item.customExts.type == 'designerOrder'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                        <div class="custom-price">¥<span style="font-size:14px;">{{fen2yuan(item.customExts.price)}}</span></div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" :class="item.customExts.actionStatus?'custom-ok':'custom-ok-cleal'" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>

                  <div  v-if="item.customExts.type == 'designerChangeOrder'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                        <div class="custom-price">{{fen2yuan(item.customExts.price)>0?'¥':''}}<span style="font-size:14px;">{{fen2yuan(item.customExts.price)>0?fen2yuan(item.customExts.price):'免费改图'}}</span></div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" :class="item.customExts.actionStatus?'custom-ok':'custom-ok-cleal'" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>

                  <div  v-if="item.customExts.type == 'designerFixOrder'">
                    <div>{{item.customExts.actionNo}}</div>
                    <div class="custom-body">
                      <div class="custom-img">
                        <img :src="item.customExts.image"/>
                      </div>
                      <div class="custom-text">
                        <div class="custom-name">{{getStringExt(item.customExts.name)}}</div>
                        <div class="custom-price">{{fen2yuan(item.customExts.price)>0?'¥':''}}<span style="font-size:14px;">{{fen2yuan(item.customExts.price)>0?fen2yuan(item.customExts.price):'免费改图'}}</span></div>
                      </div>
                    </div>
                    <div class="custom-action">
                      <a-button type="default" :class="item.customExts.actionStatus?'custom-ok':'custom-ok-cleal'" @click="preInfos(item)">{{item.customExts.actionName}}</a-button>
                    </div>
                  </div>

                  
                </div>
              </div>
              
              <div class="user-header">
                  <img :src="item.avatarurl">
              </div>
            </div>
            <div class="text-body-my" v-if="item.type == 'audio' && item.redfy == 1" style="margin-right: 60px; max-width: 200px;text-align: right;padding-bottom: 2px;float:right;" >
                  <div>{{item.redtext}}</div>
                  <div style="width: 100%; text-align: center;cursor: pointer;" @click="coloseRed(item)"><img style="width: 20px;"  src="@/assets/upred.png"  ></div>
            </div>
            <div class="message-time-my" style="clear: both; padding-top: 6px;">{{ item.time }}</div>
          </div>
        </div>
        <a-spin v-if="islond" />
      </div>
      <div class="intext-tuo" id="intextTuo" @mousedown="down" @mouseup="up">
        <div class="intext" @mousedown.stop="" @mouseup.stop="up">
          <div class="intext-action" >
            <div class="left-action">
              <div class="bq" @click="openEmo"></div>
              <div class="tp" @click="cheoosImage"></div>
              <div class="wj" @click="cheoosfile"></div>
              <div class="ls" @click="cheoosHistory"></div>
              <div class="yy" @click="openViode"></div>
              <div class="sp" @click="SendViode"></div>
             
            </div>
            <div class="right-action">
              <div style="display:flex;  cursor: pointer;">
                <div @click="openOrders" class="yqtext" style="display:flex;">
                  <div class="yq"></div>
                  <div >邀请下单</div>
                </div>
                <div class="order" @click="cheoosExts"></div>
              </div>
            </div>
          </div>
          <div class="inputbox" >
            <textarea ref="editable" @paste="onPasteImage" @keyup.enter="sendTextMessage" onkeydown="if (event.keyCode === 13) event.preventDefault();" v-model="textContent" class="chat_content_editable" spellcheck="false" contenteditable="true" placeholder="请输入消息内容...">
            </textarea>
            <button  class="send-button" @click="sendTextMessage">发送</button>
          </div>
        </div>
      </div>
      <a-modal title="" style="bottom: 20px" v-model="emoVisible" :closable="false" :dialogStyle="dialogStyle" :footer="null" width="274px" height="274px">
        <div class="emobox">
          <div v-for="(item, index) in emojis" :key="index" class="emoitem" @click="addOneEmoji(item)">
            <img class="emoitemimg" :src="getEmimgF(item)">
          </div>
        </div>
      </a-modal>
      <input ref="uploadImgs" type="file" multiple style="display:none" single accept="image/*" @change="sendImagesMessage('common')" />
      <input ref="uploadFiles" type="file" style="display:none" single @change="sendFilesMessages" />
      <input ref="uploadVideo" type="file" style="display:none" single @change="sendVideoMessages" />
      <CollectAudio v-if="isShowRecordBox" @sendAudioMessages="sendAudioMessages" @backAudio="backAudio" />
      <a-modal title="" class="imgdialog" style="bottom: 20px" v-model="imgVisible" :closable="false"  :footer="null" width="960px" height="640px">
        <div class="imgheader">
          <div class="imgtitle">图片预览</div>
          <div class="imgtitleaction">
            <div class="close" style="margin-top: 5px; margin-right: 0px;" @click="minimg()"></div>
          </div>
        </div>
        <div class="imgbox">
          <img :src="preimgurl" draggable="true" :style="{transform:'scale('+multiples+')'}"  @dragstart="dragstart($event)" @dragend="dragend($event)" >
          
        </div>
        <div class="imgaction">
          <a href="#" style="margin-left: 10px;" @click="sximg"><img src="@/assets/suoxiao.png"/></a>
          <a href="#" style="margin-left: 10px;" @click="pfimg"><img src="@/assets/fangda.png"/></a>
          <a :href="preimgurl" style="margin-left: 10px;" download><img src="@/assets/xiazai.png"/></a>
        </div>
      </a-modal>

      <a-modal title="" class="imgdialog-order" style="bottom: 20px" v-model="ProructVisible" :closable="false"  :footer="null" width="600px" height="570px">
        <div class="imgheader-order">
          <div class="imgtitle-order">邀请下单</div>
          <div class="imgtitleaction">
            <div class="close-order" style="margin-top: 16px; margin-right: 0px;" @click="closeProduct()"></div>
          </div>
        </div>
        <div class="imgbox-order">
            <div class="imgbox-order-left">
              <div class="action-item"><input class="login-input" maxlength="20" v-model="seachvalue" placeholder="搜索在售商品" /><div class="seach-button" @click="seachList"></div></div>
              <div class="z-tabs">
                <div class="z-tab" :class="orderType == 1 ?'z-tab-active' :''" @click="setOstill(1)">设计商品</div>
                <div class="z-tab" :class="orderType == 0 ?'z-tab-active' :''" @click="setOstill(0)">普通商品</div>
              </div>
              <div class="imbox-list">
                <div class="plist-item" v-for="item in productList" :key="item.id">
                  <div class="item-image">
                    <img :src="item.picUrl">
                  </div>
                  <div class="item-body">
                     <div class="item-body-name">{{item.name}}</div>
                     <div class="item-body-desc">
                        <div class="item-body-amount">￥ {{(item.price/100).toFixed(2)}}</div>
                        <div :class="item.select?'item-body-button':'item-body-button-active'" @click="setProduct(item)">{{item.select?'已添加':'添加'}}</div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="imgbox-order-right">
                <div class="right-title">
                   <div class="right-title-text">下单清单</div>
                   <div class="right-title-action" @click="clearProduct">清空</div>
                </div>
                <div class="select-list">
                   <div class="select-item" v-for="item in productList" :key="item.id">
                      <div v-if="item.select" style="display: flex;justify-content: space-between;">
                        <div class="select-image-icon" @click="clearNum(item)">
                          <img src="~@/assets/select-icon.png">
                        </div>
                        <div class="select-image">
                          <img :src="getSpcePicurl(item)">
                        </div>
                        <div style="margin-left:10px; display: flex;flex-direction: column;justify-content: space-between;">
                          <div style="font-size:12px;">{{item.name}}</div>
                          <div style="font-size:12px;">
                            <select v-model="item.spuId" class="box-select">
                              <option v-for="spce in item.spces" :key="spce.id" :value="spce.id">{{spce.text}}</option>
                            </select>
                          </div>
                          <div style="display: flex; justify-content: space-between;">
                            <div>￥ {{(getSpcePicAmount(item)/100).toFixed(2)}}/㎡</div>
                            <div>
                              <input class="box-input" v-model="item.addnum"/>
                            </div>
                          </div>
                        </div>
                      </div>
                   </div>
                </div>
                <div class="right-foot">
                    <div class="right-foot-num">已选{{getSelectNum()}}件</div>
                    <div>
                      <div class="right-foot-amount">合计<span style="font-size:10;color:#ED2616;">￥</span><span style="font-size:16;color:#ED2616;font-weight: bold;">{{getSelectAmount()}}</span></div>
                      <div class="right-foot-button" @click="comSend">发送</div>
                    </div>
                    
                </div>
            </div>
        </div>
      
      </a-modal>
      </div>

      <a-modal title="" v-model="showImagePlant" style="bottom: 20px" :closable="false"  :footer="null" width="540px" height="368px" >
        <div class="imgheader-order">
          <div class="imgtitle-order">是否发送图片</div>
          <div class="imgtitleaction">
            <div class="close-order" style="margin-top: 16px; margin-right: 0px;" @click="closeImagePlant()"></div>
          </div>
        </div>
        <div class="imgheader-order">
          <div style="height:200px;padding: 20px;">
            <img  style="height:100px;width:100px;" :src="fileBase64"/>
          </div>
        </div>
        <div class="right-foot" style="height:25px;border:none;">
          <div class="right-foot-button" @click="sendImageSum">发送</div>
        </div>
      </a-modal>
      <a-modal title="" v-model="showFilePlant" style="bottom: 20px" :closable="false"  :footer="null" width="540px" height="368px" >
        <div class="imgheader-order">
          <div class="imgtitle-order">是否发送文件</div>
          <div class="imgtitleaction">
            <div class="close-order" style="margin-top: 16px; margin-right: 0px;" @click="closeFilePlant()"></div>
          </div>
        </div>
        <div class="imgheader-order">
          <div style="height:200px;padding: 20px;">
              <div>文件名称：{{fileObj?fileObj.name:""}}</div>
          </div>
        </div>
        <div class="imgheader-order">
          <div class="right-foot-button" @click="sendImageSum">发送</div>
        </div>
      </a-modal>
      
      <CollectInfos v-if="isShowInfos" class="history" :uid="userid" :chatType="type" @updateInfos="updateInfos" />
      <CollectExts v-if="isShowExts" class="history"  :uid="userid" :chatType="type" @updateInfos="updateInfos" @gotoGroup="gotoGroup" @backCy="backCy" @onBackZdy="onBackZdy" @onBackZj="onBackZj" />
      
      <!-- 历史消息 -->
      <a-modal title="" v-model="isShowHistory" style="bottom: 20px" :closable="false"  :footer="null" width="540px" height="368px" >
        <CollectHistory v-if="isShowHistory" class="history" style="border:none;width:auto;" :uid="userid" :chatType="type" @updateInfos="updateInfos" @closemin="closemin"  />
      </a-modal>


   


      <!-- 立即评价 -->
      <a-modal title="" style="bottom: 20px" v-model="pjshow" :closable="false"  :footer="null" width="274px" height="274px" >
        <CollectPj></CollectPj>
      </a-modal>

       <!-- 商品详情 -->
       <a-modal title="" style="bottom: 20px" v-model="goodshow" :closable="false"  :footer="null" width="274px" height="274px" >
        <CollectGoods></CollectGoods>
      </a-modal>

      <!-- 订单详情 -->
      <a-modal title="" style="bottom: 20px" v-model="ordershow" :closable="false"  :footer="null" width="274px" height="274px" >
        <CollectOrder></CollectOrder>
      </a-modal>

       <!-- 查看需求 -->
       <a-modal title="" style="bottom: 20px" v-model="xqshow" :closable="false"  :footer="null" width="274px" height="274px" >
        <CollectXq></CollectXq>
      </a-modal>

      <!-- 查看需求 -->
      <a-modal title="" style="bottom: 20px" v-model="fashow" :closable="false"  :footer="null" width="274px" height="274px" >
        <CollectFa></CollectFa>
      </a-modal>
      
    </div>

   
  </div>
</template>
<script>
import storage from 'store2'
import { ipcApiRoute ,requestHttpGet,requestHttpPostByOnline} from '@/api/main'
import { createMessage } from '@/utils/handleSomeData'
import emMap  from '@/utils/em'
import { MessageUtils } from '@/utils/MessageUtils'
import { messageType } from '@/constant'
const { ALL_MESSAGE_TYPE } = messageType
import { EaseChatClient, EaseChatSDK } from '@/IM/initwebsdk'

import dateFormat from '@/utils/dateFormater'
import { emojis } from '@/constant'
import fileSizeFormat from '@/utils/fileSizeFormat'
import _ from 'lodash'
import CollectAudio from '@/components/audio.vue'
import CollectHistory from '@/components/history.vue'
import CollectInfos from '@/components/infos.vue'
import CollectExts from '@/components/exts.vue'
import CollectPj from '@/components/pj.vue'
import CollectOrder from '@/components/order.vue'
import CollectXq from '@/components/xq.vue'
import CollectFa from '@/components/fa.vue'
import CollectGoods from '@/components/goods.vue'
import BenzAMRRecorder from 'benz-amr-recorder'
import KDXF from '@/Kdxf/kdxf'
import previewImage from "@/components/elimage.vue"
import apiUrl from '@/utils/config'
 
export default {
  components: {
    CollectAudio,
    CollectHistory,
    CollectExts,
    CollectInfos,
    CollectPj,
    CollectOrder,
    CollectXq,
    CollectFa,
    CollectGoods,
    previewImage
  },
  props: {
    uid: {
      type: String,
      default: ''
    },
    chatType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileBase64:"",
      showImagePlant:false,
      showFilePlant:false,
      loading:false,
      emMap:emMap,
      fromName:"",
      pjshow:false,
      ordershow:false,
      xqshow:false,
      fashow:false,
      ProructVisible:false,
      goodshow:false,
      islond:false,
      multiples:1,
      textContent: "",
      oldY: 0,
      flag: false,
      targetDiv: '',
      targetDivHeight: 0,
      MessageList: [],
      userid: "",
      type: "",
      username:"",
      emoVisible: false,
      dialogStyle: {
        top: "100px",
        "margin-left": "280px"
      },
      emojis: emojis,
      fileObj: null,
      fileSizeFormat: fileSizeFormat,
      isShowRecordBox: false,
      imgVisible:false,
      preimgurl:"",
      initWidth: 0, // 父元素的宽-自适应值
      initHeight: 0, // 父元素的高-自适应值
      startclientX: 0, // 元素拖拽前距离浏览器的X轴位置
      startclientY: 0, //元素拖拽前距离浏览器的Y轴位置
      elLeft: 0, // 元素的左偏移量
      elTop: 0, // 元素的右偏移量
      isShowHistory:false,
      isShowInfos:false,
      isShowExts :false,
      page:-1,
      loadList:[],
      cyy:"",
      armRec:null,
      audioPlayStatus:[],
      fromNameZ:false,
      orderType:1,
      seachvalue:"",
      productList:[]
    };
  },
  watch: {
    uid: function () {
      this.userid = this.uid
      this.MessageList = []
      this.getHostList()
    },
    chatType: function () {
      this.type = this.chatType
    }
  },
  mounted() {
    // console.log("初始化")
    









    let that = this
    //新消息通知
    this.$bus.$off('newMessageHost')
    this.$bus.$on("newMessageHost",async (data) => {
      console.log("列表收到消息通知")
      //如果消息和当前会话类型匹配 说明读到到消息
      console.log(data)
      console.log(this.userid)
      if(data.chatType == "groupChat"){
        if(data.to == this.userid){
          console.log("添加列表新消息")
          that.MessageList.push(data)
          console.log(that.MessageList)
          await MessageUtils.sendMessageNoteGoBack(data.to,this.type,data.id,this)
        }
      }else{
        if(data.from == this.userid){
          that.MessageList.push(data)
          await MessageUtils.sendMessageNoteGoBack(data.from,this.type,data.id,this)
        }
      }
      MessageUtils.autoNewSaveFile(data,this.$ipc)
      let scrollEl = document.getElementById("message")
      if(scrollEl){
        if(scrollEl.scrollHeight - scrollEl.scrollTop - scrollEl.offsetHeight == 108){
          setTimeout(() => {
            let scrollEl = document.getElementById("message")
            scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
          }, 50);
        }
      }
    })
    //消息送达通知
    this.$bus.$off('pushDeliverMessage')
    this.$bus.$on("pushDeliverMessage",async (data) => {
      // console.log("收到送达对方客服端的消息通知",data)
      setTimeout(() => {
        that.MessageList.forEach(e => {
          if(e.id == data.mid){
            e.id = data.mid
          }
        });
        this.$forceUpdate();
      }, 1000);
    })
    //消息送达服务通知
    this.$bus.$off('pushReceivedMessage')
    this.$bus.$on("pushReceivedMessage",async (data) => {
      //本地消息发出后赋值服务器消息ID 
      setTimeout(() => {
        for (let index = 0; index < that.MessageList.length; index++) {
          let e = that.MessageList[index];
          if(e.chatType == "groupChat" && data.to == this.userid){
                // e.deliver = true
          }else{
            if(that.MessageList[index].id == data.id){
              // console.log("本地消息发出后赋值服务器消息ID ")
              that.MessageList[index].id = data.mid
            }
          }
        }
        that.$forceUpdate();
       }, 1000);
    })
    //会话阅读回执
    this.$bus.$off('pushChannelMessage')
    this.$bus.$on("pushChannelMessage",async (data) => {
      setTimeout(() => {
        console.log("会话阅读回执",data)
      }, 1500);
    })
    //消息阅读回执
    this.$bus.$off('pushReadMessage')
    this.$bus.$on("pushReadMessage",async (data) => {
      setTimeout(() => {
        console.log("消息阅读回执",data)
        that.MessageList.forEach(e => {
          if(e.id == data.mid){
             e.deliver = true
          }
        });
        this.$forceUpdate();
        console.log(that.MessageList)
      }, 1500);
    })
    
    
  },
  methods: {
    closeImagePlant(){
      this.showImagePlant = false
    },
    closeFilePlant(){
      this.showFilePlant = false
    },
    getSpcePicurl(item){
        let pic = ""
        item.spces.forEach(e=>{
            if(e.id == item.spuId){
              pic = e.picUrl
            }
        })
        return pic
    },
    getSpcePicAmount(item){
      let amount = ""
        item.spces.forEach(e=>{
            if(e.id == item.spuId){
              amount = e.price
            }
        })
        return amount
    },
    getSelectAmount(){
      let p = 0
      this.productList.forEach(e=>{
        if(e.select){
          e.spces.forEach(s=>{
            if(e.spuId == s.id){
              p =  p + ( parseInt(s.price) * parseInt(e.addnum));
            }
          })
        }
      })
      if(p == 0){
        return 0
      }else{
        return (p/100).toFixed(2);
      }
     
    },
    getSelectNum(){
      let p = 0
      this.productList.forEach(e=>{
        if(e.select){
          p= parseInt(p) + parseInt(e.addnum);
        }
      })
      return p;
    },
    clearNum(item){
      item.select = false
    },
    setProduct(item){
      item.select = !item.select
    },
    seachList(){
      this.getProduct()
    },
    setOstill(e){
      this.orderType = e
      this.getProduct()
    },
    clearProduct(){
      this.productList.forEach(e=>{
        e.select = false
        e.addnum = 1
      })
    },
    async getProduct(){
      let obj = {}
      if(this.seachvalue !=""){
        obj.name = this.seachvalue
      }
      obj.shopType = storage.get("shopType")
      let res = await requestHttpPostByOnline(apiUrl+'/admin-api/product/spu/get-list-pc-im',obj)
      this.productList = []
      res.data.forEach(e=>{
        e.select = false
        e.addnum = 1
        e.spces = []
        e.skus.forEach(d=>{
          let obj = {}
          obj.id = d.id
          obj.picUrl = d.picUrl
          obj.price = d.price
          obj.text = []
					d.properties.forEach(q=>{
            obj.text.push(q.valueName)
					})
          obj.text = obj.text.join("-")
          e.spces.push(obj)
				})
        e.spuId = e.spces[0].id
        this.productList.push(e)
      })
      console.log(this.productList)
      console.log(res)
    },
    getTypes(v){
      if(v.type == "txt"){
        let textmsg = v.msg
        if(textmsg.indexOf("http") == 0){
          if(textmsg.indexOf("Goods_Goods")>=0 || textmsg.indexOf("Supplier_Goods")>=0){
            //设计商品
            //普通商品
            return "goods"
          }else if(textmsg.indexOf("Shop")>=0){
            //店铺
            return "goods"
          }else if(textmsg.indexOf("renderingHall")>=0){
            //效果图
            return "goods"
          }else{
            //网址
            return "http"
          }
        }
      }
      return "false"
    },
     /**
         * 将分转成元
         *
         * @param price 分，例如说 100 分
         * @returns {string} 元，例如说 1.00 元
         */
    fen2yuan(price) {
      return (price / 100.0).toFixed(2)
    },
    loadMore(){
      console.log("点击加载更多")
      this.orderscrollEvent()
    },
    getEmimg(item){
      if(item.msg.indexOf('[') >=0 ){
        let msgs = item.msg
        //分析出所有表情
        for (const key in this.emMap) {
          if(item.msg.indexOf(key) >= 0){
            msgs = msgs.replace(key,'<img src="'+require('../assets/faces/' + this.emMap[key])+'"/>')
          }
        }
        return msgs
      }else{ 
        return item.msg
      }
    },
    getEmimgF(item){
      if(item.indexOf('[') >=0 ){
        return require('../assets/faces/' + this.emMap[item])
      }else{ 
        return item
      }
    },
    sximg(){
      if (this.multiples <= 1) {
        return;
      }       
      this.multiples -= 0.25;
    },
    pfimg(){
      if (this.multiples >= 2) {
        return;
      }       
      this.multiples += 0.25;
    },
    getMyNameID(){
      return storage.get("ImAcount")
    },
    async getFromUserName(){
     
      if(this.type != "groupChat"){
        let userinfo = await EaseChatClient.fetchUserInfoById(this.userid)
        console.log(userinfo)
        this.fromName = userinfo.data[this.userid]['nickname']
        let groupNoteInfo = await requestHttpGet(apiUrl+'/admin-api/supplier/conversation-remark/get-info', {
            conversationId: this.userid, userId: storage.get("ImAcount")
        })
        console.log(groupNoteInfo)
        if(groupNoteInfo.code == 0 && groupNoteInfo.data!=null){
          this.fromName  =  groupNoteInfo.data.name
        }
        //获取装修标识
        let res = await requestHttpGet(apiUrl+'/admin-api/supplier/user/user-member-info', {
          id:this.userid.split("im")[1].substring(0,this.userid.split("im")[1].length-1)
        })
        if(res.data.identityType == '1'){
          this.fromNameZ = true
        }else{
          this.fromNameZ = false
        }

      }else{
        let groupinfo = await EaseChatClient.getGroupInfo({groupId: this.userid})
        console.log(groupinfo)
        this.fromName = groupinfo.data[0]['name']

        let groupNoteInfo = await requestHttpGet(apiUrl+'/admin-api/supplier/conversation-remark/get-info', {
            conversationId: this.userid, userId: storage.get("ImAcount")
        })
        if(groupNoteInfo.code == 0 && groupNoteInfo.data!=null){
          this.fromName  =  groupNoteInfo.data.name
        }
      }
    },
    preInfos(e){
      if(!e.customExts){
        this.goodshow = true
        return
      }
      if(e.customExts.type == "forv"){
        //足迹
        console.log(e.customExts.goods_id)
          //判断类型  -- 详情
        if(e.customExts.status == "点击查看详情"){
          this.goodshow = true
        }
      }else{
        //订单
        console.log(e.customExts.order_id)
        console.log(e.customExts.status)
        //判断类型  -- 评价
        if(e.customExts.status == "请您对我们的方案做出评价"){
          this.pjshow = true
        }
        //判断类型  -- 详情
        if(e.customExts.status == "点击查看详情"){
          this.ordershow = true
        }

        //判断类型 -- 查看需求
        if(e.customExts.status == "请确定申请修改"){
          this.xqshow = true
        }

        //判断类型 -- 查看需求
        if(e.customExts.status == "请您仔细查阅需求并进行确认"){
          this.xqshow = true
        }

        if(e.customExts.status == "查看商品详情"){
          this.goodshow = true
        }
        //判断类型 -- 查看方案
        if(e.customExts.status == "请您确定设计方案"){
          this.fashow = true
        }

      }
    },
    coloseRed(item){
      item.redfy = 0
      this.$forceUpdate();
    },
    redAudio(item,index){
      // const a = document.createElement("a")
      // a.setAttribute('href',item.url);
      // a.setAttribute('download',"dd");
      // a.click();

      item.redfy = 1;
      this.$forceUpdate();
      if(item.redtext){
        return
      }
      console.log(item.url)
      let that = this
      KDXF.redfile(item.url,(redtext)=>{
        item.redtext = redtext
        that.$forceUpdate();
      });
      
    },
    startplayAudio(item,index){
      
      if(this.audioPlayStatus[index] == 1 ){
        console.log("暂停")
        this.armRec.pause()
        this.audioPlayStatus[index] = 2
        return 
      }else if(this.audioPlayStatus[index] == 2){
        console.log("继续播放")
        this.armRec.resume()
        this.audioPlayStatus[index] = 1
        return 
      }else if(this.audioPlayStatus[index] == 0){
        this.armRec.stop()
      }else{
        if(this.audioPlayStatus.length>0){
          this.armRec.stop()
        }
      }

      this.armRec = new BenzAMRRecorder()
      const src = item.url
      console.log('>>>>>开始播放音频', item.url)
      //初始化音频源并调用播放
      this.armRec.initWithUrl(src).then(() => {
        this.armRec.play()
        this.audioPlayStatus[index] = 1
      })
      //播放开始监听
      this.armRec.onPlay(() => {
        console.log("播放事件")
        // this.audioPlayStatus[index] = 1
      })
      //播放结束监听
      this.armRec.onPause(() => {
        this.audioPlayStatus[index] = 2
        console.log("暂停事件")
      })
      //播放结束监听
      this.armRec.onStop(() => {
        console.log("停止事件")
        this.audioPlayStatus[index] = 0

      })
    },
    async orderscrollEvent(e){
        var that = this
        if(!that.loading){
          that.loading = true
          //加载更多
          this.page = this.MessageList[0].id
          that.loadList = await MessageUtils.getHistoryMessages(this.userid,this.type,this.page)
          setTimeout(() => {
            for(let index = that.loadList.length-1; index >=0; index--) {
              that.MessageList.unshift(that.loadList[index])
            }
            that.$forceUpdate();
            console.log(that.MessageList)
            that.loading = false
          }, 300);
        }
    },
    //截取字符串长度
    getStringExt(e){
      if(e && e.length > 24){
        return e.substring(0,24)+'...'
      }
      return e
    },
    //回调自定义消息 
    async onBackZdy(e,type){
      // 获取自定义消息类型 设计订单  商品订单
      console.log(e)
      let obj = {
        id:e.id, //订单id
        name:e.items[0].spuName, //商品名称
        image:e.items[0].picUrl, //商品图片
        price:e.payPrice, //订单金额
        status:e.status, //订单状态
        refundStatus:e.refundStatus, //退款状态
        type:type,//自定义消息类型
      }
      await MessageUtils.sendCustomMessages(this.userid,this.type,obj,this.MessageList,this)
      setTimeout(() => {
          let scrollEl = document.getElementById("message")
          scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
      }, 50);
    },
    //回自定义足迹消息
    async onBackZj(e,type){
      // 获取自定义消息类型 效果图  设计商品
      console.log(e)
      // 区分 
      for (let index = 0; index < e.length; index++) {
        const item = e[index];
        let obj = {}
        if(item.type == 4 ){
            //设计商品
            obj.id = item.footprintDesignerProduct.id //商品id
            obj.name = item.footprintDesignerProduct.name //商品名称
            obj.image = item.footprintDesignerProduct.picUrl //商品图片
            obj.price = item.footprintDesignerProduct.price
            obj.type = type
        }
        if(item.type == 3 ){
            //效果图
            obj.id = item.footprintRender.id //效果图d
            obj.name = item.footprintRender.name //效果图名称
            obj.image = item.footprintRender.coverPicUrls.split(",")[0] //效果图图片
            obj.price = 0
            obj.type = type
        }
        console.log(obj)
        //发送自定义消息
        await MessageUtils.sendCustomMessages(this.userid,this.type,obj,this.MessageList,this)
      }
      setTimeout(() => {
          let scrollEl = document.getElementById("message")
          scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
      }, 50);
    },
    //设置内容
    backCy(e){
      this.cyy = e 
      this.textContent = e.text
    },
    //获取用户信息
    async getUserinfoByto(toid){
      let userinfo = await EaseChatClient.fetchUserInfoById(toid)
      console.log(userinfo.data[toid]['avatarurl'])
      return userinfo.data[toid]['avatarurl']
    },
    updateInfos(e){
      this.$emit('updateInfos',e)
    },
    gotoGroup(e){
      console.log(e)
      this.$emit('gotoGroup',e)
    },
    closemin(){
      this.isShowInfos = false
      this.isShowExts = false
      this.isShowHistory = false
    },
    dragstart(e) {
      console.log(e);
      this.startclientX = e.clientX; // 记录拖拽元素初始位置
      this.startclientY = e.clientY;
    },
    // 拖拽完成事件
    dragend(e) {
      console.log(e);
      let x = e.clientX - this.startclientX; // 计算偏移量
      let y = e.clientY - this.startclientY;
     
      this.elLeft += x; // 实现拖拽元素随偏移量移动
      this.elTop += y;
      console.log(this.elLeft)
    },
    preImg(url){
      if (this.$ipc) {
        storage.set("preImages",url)
        this.$ipc.invoke(ipcApiRoute.newWinSize, {name:"preimg",content:window.location.protocol+"//"+window.location.host+"/#/preimg",title:'图片预览'}).then(result => {
            console.log(result)
        })
      } else {
        this.preimgurl = url
        this.imgVisible = true
      }
    },
    getimage(item){
      let res = require("@/assets/pngfile.png")
      switch (item.split(".")[1]) {
        case 'png':
            res = require("@/assets/pngfile.png")
          break;
        case 'doc':
            res =  require("@/assets/wfile.png")
          break;
        case 'xlsx':
            res =  require("@/assets/xfile.png")
          break;
        case 'dwg':
            res =  require("@/assets/cadfile.png")
          break;
        case 'zip':
          res =  require("@/assets/zfile.png")
          break;
        case 'pptx':
          res =  require("@/assets/pfile.png")
          break;
        default:
          break;
      }
      return res
    },
    openViode() {
      this.isShowRecordBox = true
    },
    backAudio(){
      this.isShowRecordBox = false
    },

    
    async sendVideoMessages() {
      await MessageUtils.sendVideoMessages(this.userid,this.type,this.$refs.uploadVideo.files[0],this.MessageList,this)
      setTimeout(() => {
          let scrollEl = document.getElementById("message")
          scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
      }, 50);
      this.isShowRecordBox = false
    },
    async sendAudioMessages(audioData) {
      await MessageUtils.sendAudioMessages(this.userid,this.type,audioData,this.MessageList,this)
      setTimeout(() => {
          let scrollEl = document.getElementById("message")
          scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
      }, 50);
      this.isShowRecordBox = false
    },
    onPasteImage(event) {
      let that = this
      console.log('>>>>>>监听粘贴事件', event)
      const data = (event.clipboardData || window.clipboardData)
      //获取图片内容
      const imgContent = data.items[0]
      console.log(imgContent)
      console.log(imgContent.getAsFile())
      const reader = new FileReader()
      if (imgContent.kind === 'file' && imgContent.type.startsWith('image/')) {
        //图片
        that.fileObj = imgContent.getAsFile()
        reader.readAsDataURL(imgContent.getAsFile())
        reader.onload = (event) => {
          //获取base64流
          that.fileBase64 = event.target.result
          console.log("发送图片",that.fileBase64 )
          that.sendImagePlant()
        }
      }
      if (imgContent.kind === 'file' && !imgContent.type.startsWith('image/')) {
        //文件
        console.log("发送文件")
        that.fileObj = imgContent.getAsFile()
        that.sendFilePlant()
      }
    },
    sendImagePlant(){
      this.showImagePlant = true
    },
    sendFilePlant(){
      this.showFilePlant = true
    },
    sendImageSum(){
      this.sendImagesMessage('other')
      this.showImagePlant = false
      this.showFilePlant = false
    },
    openOrders(){
      console.log("dwqd")
      this.ProructVisible = true
      this.getProduct()
    },
    openDir(id){
      if (this.$ipc) {
        this.$ipc.invoke(ipcApiRoute.openFile, {id:id}).then(result => {
            console.log(result)
        })
      }
    },
    cheoosImage() {
      this.$refs.uploadImgs.click()
    },
    cheoosfile() {
      this.$refs.uploadFiles.click()
    },
    SendViode(){
      this.$refs.uploadVideo.click()
    },
    cheoosHistory(){
      this.isShowInfos = false
      this.isShowExts = false
      this.isShowHistory = !this.isShowHistory
    },
    cheoosInfos(){
      if(this.type == "groupChat"){
        this.isShowHistory = false
        this.isShowExts = false
        this.isShowInfos = !this.isShowInfos 
      }
    },
    cheoosExts(){
      if(this.type != "groupChat"){
        this.isShowInfos = false
        this.isShowHistory = false
        this.isShowExts = !this.isShowExts 
      }else if(this.type == "groupChat"){
        this.isShowInfos = false
        this.isShowHistory = false
        this.isShowExts = !this.isShowExts 
      }
    },
    async sendFilesMessages() {
      await MessageUtils.sendFilesMessages(this.userid,this.type,this.$refs.uploadFiles.files[0],this.MessageList,this)
      setTimeout(() => {
        let scrollEl = document.getElementById("message")
        scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
      }, 50);
      this.$refs.uploadFiles.value = null
    },
    async sendImagesMessage(type) {
      this.islond = true
      if(type == 'common'){
        for (const key in this.$refs.uploadImgs.files) {
          await MessageUtils.sendImagesMessage(this.userid,this.type,this.$refs.uploadImgs.files[key],this.MessageList,e=>{
            console.log("回调消息")
            setTimeout(() => {
              let scrollEl = document.getElementById("message")
              scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
            },300);
            this.$emit('sendM', e)
            this.$refs.uploadImgs.value = null
            this.islond = false
          })
        }
      }else if(type == 'other'){
        console.log("发送文件1")
        console.log(this.fileObj)
        await MessageUtils.sendFilesMessages(this.userid,this.type,this.fileObj,this.MessageList,this)
       
        setTimeout(() => {
          let scrollEl = document.getElementById("message")
          scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
        }, 300);
        this.fileObj = null
      }
      this.islond = false
    },
    sendM(){
      console.log("发送消息成功")
    },
    addOneEmoji(emoji) {
      this.textContent = this.textContent + emoji
      this.emoVisible = !this.emoVisible
      // this.sendTextMessage()
    },
    openEmo() {
      this.dialogStyle.top = document.getElementById('message').offsetHeight - (62) + "px"
      this.emoVisible = !this.emoVisible
    },
    //初始化历史消息
    async getHostList() {
      console.log("初始化消息",this.userid)
   
      if(!this.type){
        return
      }
      this.MessageList = []
      if (storage.get('ImAcount') == null) {
        console.log("请登录")
        if (!this.$ipc) {
          this.$router.push('/login')
        }
        return
      }
      if (!this.userid) {
        console.log(this.userid)
        console.log("无会话消息")
        return
      }
      this.getFromUserName()
      this.username = storage.get('UserName')
      console.log(this.type)
      this.page = -1
      this.MessageList = await MessageUtils.getHistoryMessages(this.userid,this.type,this.page)
      await MessageUtils.autoSaveFile(this.MessageList,this.$ipc)
      //回复已读消息
      this.MessageList .forEach(el => {
        if(el.from != storage.get("ImAcount") && el.chatType != "groupChat" ){
            MessageUtils.sendMessageNoteGoBack(el.from,el.chatType,el.id,this)
        }
      });
      this.$forceUpdate();
      setTimeout(() => {
        let scrollEl = document.getElementById("message")
        scrollEl.scrollTo({ top: scrollEl.scrollHeight + 200, behavior: 'smooth' });
      }, 500);
      console.log(this.MessageList)
    },
    //发送文本消息
    async sendTextMessage() {
      //获取是否是常用语
      // if(this.cyy != ""){
      //   //调用常用语统计
      //   let obj = {
      //     k: storage.get('ks'),
      //     u: storage.get('us'),
      //     id:this.cyy.id
      //   }
      //   await requestHttpGet('https://www.binliyoujia.com/index.php?ctl=Buyer_Designer&met=imwords_usernum&typ=json', obj)
      //   this.cyy = ""
      // }
      // console.log(this.textContent);
      await MessageUtils.sendTextMessage(this.userid,this.type,this.textContent,this.MessageList,this)
      this.textContent = ""
      setTimeout(() => {
          let scrollEl = document.getElementById("message")
          scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
      }, 50);

    },
    down(e) {
      this.flag = true
      this.oldY = e.pageY; //记录鼠标按下位置
      this.targetDiv = document.getElementById('intextTuo');
      this.targetDivHeight = this.targetDiv.offsetHeight;
      this.targetTopDiv = document.getElementById('message');
      this.targetTopDivHeight = this.targetTopDiv.offsetHeight;
      this.startTd(e)
    },
    startTd(ev) {
      let height = 0
      let moveY = 0
      this.flag = true
      window.addEventListener('mousemove', e => {
        if (this.flag) {
          moveY = e.pageY; //检测鼠标移动位置
          height = this.oldY - moveY
          if (height > 0) {
            if (this.targetDivHeight + Math.abs(height) <= 400) {
              document.getElementById('intextTuo').style.height = this.targetDivHeight + Math.abs(height) + "px"
              document.getElementById('message').style.height = this.targetTopDivHeight - Math.abs(height) + "px"
            }
          } else {
            if (this.targetDivHeight - Math.abs(height) >= 122) {
              document.getElementById('intextTuo').style.height = this.targetDivHeight - Math.abs(height) + "px"
              document.getElementById('message').style.height = this.targetTopDivHeight + Math.abs(height) + "px"
            }
          }
        }
        setTimeout(() => {
          let scrollEl = document.getElementById("message")
          scrollEl.scrollTo({ top: scrollEl.scrollHeight + 100, behavior: 'smooth' });
        }, 50);
      })
    
      window.addEventListener('mouseup', e => {
        this.flag = false
      })
    },
    up(e) {
      this.flag = false
    },
    min(type) {
      this.$ipc.invoke(ipcApiRoute.action, type).then(result => {
        console.log(result)
      })
    },
    minimg() {
      this.imgVisible = false
    },
    closeProduct(){
      this.ProructVisible = false
    },
    async comSend(){
      // 邀请下单
      let param = []      // [{skuId: v.skuId,count: v.num,cartId:0}]
      let productName = ""
      let productImage = ""
      let price = 0
      console.log(this.productList)
      this.productList.forEach(e=>{
        if(e.select){
            let infos = {}
            infos.skuId = e.spuId
            infos.count = e.addnum
            infos.cartId = 0
            param.push(infos)
            productName = e.name
            e.skus.forEach(s=>{
              if(s.id == e.spuId){
                price = price+(parseInt(e.addnum)*(s.price*100))
                productImage = s.picUrl
              }
            })
        }
      })
        let obj = {
          orderId:"", //订单id
          image:productImage, //商品图片
          name:productName, //商品名称
          titleName:"已帮您选择好商品规格。",
          price:price, //金额
          designerId:0, //退款状态
          tenantId:0,
          ext:JSON.stringify(param),
          type:"selectOrder"
      }

      await MessageUtils.sendCustomMessages(this.userid,this.type,obj,this.MessageList,this)

      //发送自定义订单消息
      this.ProructVisible = false
    }
    // async comSend(){
    //   // 组建订单提交数据 获取结算信息
    //   let param = []      // [{skuId: v.skuId,count: v.num,cartId:0}]
    //   let params = []
    //   this.productList.forEach(e=>{
    //     if(e.select){
    //         let infos = {}
    //         infos.skuId = e.spuId
    //         infos.count = e.addnum
    //         infos.cartId = 0
    //         param.push(infos)
    //     }
    //   })
		// 	param.forEach(e=>{
		// 		params.push({items:[e],pointStatus:false,deliveryType:1})
		// 	})
    //   let obj = {
    //     userId:this.userid.slice(2, -1),
    //     items:params
    //   }
    //   let res = await requestHttpPostByOnline(apiUrl+'/admin-api/trade/order/pc-im-designer-settlement',obj)
    //   console.log(res)
    //   if(res.code == 0){
    //       let itemsinfo = []
    //       res.data.items.forEach(e=>{
    //           e.items.forEach(o=>{
    //             itemsinfo.push({
    //               skuId:o.skuId,
    //               count:o.count,
    //               cartId:o.cartId
    //             })
    //           })
    //       })
    //       let orderRes = await requestHttpPostByOnline(apiUrl+'/admin-api/trade/order/pc-im-designer-create',{
    //         userId:this.userid.slice(2, -1),
    //         items: itemsinfo,
    //         deliveryType: 1, // 配送方式
    //         pointStatus: false // 是否使用积分
    //       })
    //       console.log(orderRes)
    //       if(orderRes.code == 0){
    //          this.textContent = "亲，已帮您选择好商品规格，喜欢的话可点击“立即支付”完成支付"
    //          this.sendTextMessage()
    //       }
    //   }
    //   this.ProructVisible = false
    // }
  }
};
</script>
<style lang="less" scoped>
.red {
  width: 10px;
  height: 10px;
  background-image: url('~@/assets/red1.png');
  background-size: 100%;
  margin-left: 10px;
  align-self: center;
  margin-top: 20px; 
}
.red2 {
  width: 10px;
  height: 10px;
  background-image: url('~@/assets/red2.png');
  background-size: 100%;
  margin-left: 10px;
  align-self: center;
  margin-top: 20px;
}

.message-time {
  text-align: left;
  margin-left: 42px;
  margin-top: 6px;
  font-family: PingFangSC-Regular;
  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
}

.message-time-my {
  text-align: right;
  margin-right: 65px;
  margin-top: 6px;
  font-family: PingFangSC-Regular;
  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
}
.text-plant{
  max-width: calc(100% - 200px);
}
.text-name{
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  text-align: left;
  margin-left: 10px;
}
.text-name-my{
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}
.text-body {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  background: #EEEEEE;
  margin-top: 6px;
}

.text-body-my {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  background: #FFF3E8;
  margin-top: 6px;
}

.message-body {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
}

.message-body-my {
  display: flex;
  justify-content: flex-end;
  vertical-align: middle;
  margin-right: 22px;
}

.message-box {
  margin-top: 22px;
  margin-left: 22px;
  user-select: text;
  margin-bottom: 20px;
}

.user-header {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user-header img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

#app-base-file {
  width: 100%;
  background-color: #fff;
  height: 100%;
  position: relative;
}

.inputbox {
  min-height: calc(100% - 50px);
  position: relative;
}

.chat_content_editable {
  font-family: 'PingFang SC';
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  border: none;
  background: none;
  letter-spacing: .5px;
  resize: none;
  padding: 0px 22px;
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
}

textarea {
  outline: none;
}

.intext-action {
  height: 37px;
  display: flex;
  justify-content: space-between;
  margin-left: 23px;
  margin-right: 23px;
}

.left-action {
  height: 37px;
  display: flex;
  justify-content: flex-start;
}

.bq {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/bq.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.tp {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/tp.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.wj {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/wj.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.ls {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/ls.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.yy {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/yy.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.sp {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/shipin.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}


.yq {
  width: 14px;
  height: 14px;
  background-image: url('~@/assets/yqxd.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 8px;
  cursor: pointer;
}



.order {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/order.png');
  background-size: 100%;
  margin-top: 10px;
  cursor: pointer;
}

.message {
  width: 100%;
  // height: 445px;
  height: calc(100% - 125px);
  background-color: #fff;
  overflow-y: auto;
}
.message::-webkit-scrollbar{
  background-color: #fff;
  width: 3px;
}
.message::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);
}
.intext-tuo {
  height: 122px;
  opacity: 1;
  background: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  cursor: s-resize;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.intext-tuo:hover {
  cursor: s-resize;
}

.intext {
  height: calc(100% - 2px);
  margin-top: 1px;
}

.intext:hover {
  cursor: default;
}










.header {
  width: 100%;
  height: 73px;
  // background-color: blanchedalmond;
  -webkit-app-region: drag;
  -webkit-user-select: none;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px 0px #EEEEEE;
}

.header-action {
  height: 21px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.min {
  width: 11px;
  height: 11px;
  background-image: url('~@/assets/main-min.png');
  background-size: 100%;
  background-position: center;
  margin-top: 5px;
  -webkit-app-region: no-drag;
  margin-left: 5px;
}

.max {
  width: 11px;
  height: 11px;
  background-image: url('~@/assets/main-max.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5px;
  margin-left: 5px;
  -webkit-app-region: no-drag;
}

.close {
  width: 11px;
  height: 11px;
  background-image: url('~@/assets/main-close.png');
  background-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  -webkit-app-region: no-drag;
}
.close-order {
  width:18px;
  height: 18px;
  background-image: url('~@/assets/main-close.png');
  background-size: 100%;
  margin-top: 16px;
  margin-left: 5px;
  -webkit-app-region: no-drag;
}
.close-right{
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/main-close.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-app-region: no-drag;
}
.close-right:hover{
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/main-close-w.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-app-region: no-drag;
}
.min-box{
  width: 21px;
  cursor: pointer;
}
.min-box:hover{
  width: 21px;
  background-color: #EEEEEE;
}
.min-box-right{
  width: 21px;
  margin-right: 10px;
  cursor: pointer;
}
.min-box-right:hover{
  width: 21px;
  margin-right: 10px;
  background-color: #ED2616;
}
.more {
  width: 17px;
  height: 3px;
  background-image: url('~@/assets/main-more.png');
  background-size: 100%;
  margin-top: 10px;
  -webkit-app-region: no-drag;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.title {
  height: 23px;

  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  margin-left: 22px;
  margin-top: 7px;
}

.title-text {
  font-family: PingFangSC-Medium;
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0px;
  color: #222222;
}

.send-button {
  position: absolute;
  right: 23px;
  bottom: 0px;
  width: 56px;
  height: 25px;
  border-radius: 4px;
  opacity: 1;
  background: #F7F7F7;
  box-sizing: border-box;
  border: 1px solid #DDDDDD;
  cursor: pointer;
}

.emobox {
  width: 100%;
  height: 105px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.emobox::-webkit-scrollbar {
  width: 0 !important
}

.emoitem {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px;
  transition: all .3s ease;
  margin-right: 1px;
  margin-left: 0.5px;
  &:hover {
    transform: scale(1.2);
  }
}
.emoitemimg{
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.image-box {
  width: 100px;
}
.image-box-audio{
  width: 14px;
  cursor: pointer;
}
.audio-text{
  font-family: PingFangSCRegular-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #999999;
  margin-left: 8px;
}

.audio-text-my{
  font-family: PingFangSCRegular-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #999999;
  margin-left: 8px;
}
.image-box-file {
  width: 54px;
}

.file-box {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.imgheader{
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  position: relative;
}
/deep/ .ant-modal-body{
  padding: 16px;
}
.imgbox{
  display: flex;
  justify-content: center;
  height: 640px;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.imgbox img{
  max-width: 80%;
  max-height: 640px;
  position: absolute;
  z-index: 10;
  user-select: none;
}
.imgaction{
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  margin: auto;
}
.imgtitleaction{
  position: absolute;
  right: 16px;
  top: 0px;
}
.history{
  width: 260px;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #DDDDDD;
  flex-shrink: 0;
}
.custom-body{
  display: flex;
  justify-content: flex-start; 
  margin-top: 8px;
  padding:8px;
  border-radius: 5px 5px 5px 5px;
  background-color: #FFF;
}
.custom-img{
  width: 60px;
  height: 60px;
}
.custom-img img{
  width: 60px;
  height: 60px;
  border-radius: 5px 5px 5px 5px;

}
.custom-text{
  height: 60px;
  width: 180px;
  margin-left:8px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.custom-name{
  width: 100px; /* 设置容器的宽度 */
  white-space: nowrap; /* 禁止文本换行 */
  overflow: hidden; /* 隐藏超出部分的内容 */
  text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
}
.custom-action{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.custom-cel{
  border-radius: 20px;
  height: 29px;
  width: 100px;
  box-sizing: border-box;
}
.custom-ok{
  border-radius: 20px;
  height: 29px;
  font-size:12px;
  opacity: 1;
  background: linear-gradient(90deg, #FF6044 0%, #ED2616 100%);
  box-shadow: 0px 8px 8px 0px rgba(237, 38, 22, 0.1);
  border: none;
  color: #fff;
}
.custom-ok-cleal{
  border-radius: 20px;
  font-size:12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color:none;
  border:1px solid #999;
}
.custom-ok-cleal:hover{
  border-radius: 20px;
  font-size:12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color:none;
  border:1px solid #999;
  color: #ED2616 !important;
}
.custom-ok-cleal:active{
  border-radius: 20px;
  font-size:12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color:none;
  border:1px solid #999;
  color: #ED2616;
}
.custom-ok-cleal:focus{
  border-radius: 20px;
  font-size:12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color:none;
  border:1px solid #ED2616;
  color: #ED2616;
}
.loadMore{
  font-size: 12px;
  color: #999999;
  margin-top: 10px;
  cursor: pointer;
}
.loadMoreh{
  color: blue;
}
.text-body p{
  margin-bottom: 0px !important;
}
.text-body-my p{
  margin-bottom: 0px !important;
}
.custom-price{
  color:#ED2616;
  font-weight:600;
  margin-top:2px;
}
.yqtext{
  font-size:12px;
  line-height: 37px;
  margin-right: 10px;
}
.imgdialog-order{
  border-radius: 10px;
}
.imgheader-order{
  font-weight: bold;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.imgbox-order{
  display: flex;
  justify-content: space-between;
  height: 640px;
}
.imgbox-order-left{
  width: 250px;
  border-right: 1px solid #eee;
}
.imgbox-order-right{
  height: 640px;
  width: 350px;
}

.login-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #D8D8D8;
  width: 220px;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 29px;
  outline: none;
  color: #999;
}

.login-input:focus {
  outline: none;
  border: 1px solid #D8D8D8;
}

.login-input::placeholder {
  color: #999999;
}
.action-item {
  margin-top: 16px;
  margin-left: 0px;
  margin-right: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
.seach-button {
  background-image: url('~@/assets/seach.png');
  background-size: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 195px;
  cursor: pointer;
}
.z-tabs{
    display: flex;
    justify-content: flex-start;
}
.z-tab{
    border-radius: 60px;
    opacity: 1;
    background: rgba(136, 136, 136, 0.1);
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    color: #222222;
    padding: 4px 10px;
    margin-right: 10px;
    cursor: pointer;
}
.z-tab-active{
    border-radius: 60px;
    opacity: 1;
    background: rgba(237, 38, 22, 0.1);
    font-family: PingFang SC;
    font-size: 11px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 0em;
    color: #ED2616;
    padding: 4px 10px;
    margin-right: 10px;
    cursor: pointer;
}
.imbox-list{
  height: 540px;
  overflow-y: auto;
}
.plist-item{
  background: #F7F7F7;
  border-radius: 4px;
  height: 80px;
  margin-right: 16px;
  margin-top: 16px;
  padding: 10px;
  display: flex;
}
.item-image{
  width: 60px;
  height: 60px;
}
.item-image img{
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
.item-body{
  margin-left: 10px;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item-body-name{
  font-size: 12px;
}
.item-body-desc{
  display: flex;
  justify-content: space-between;
}
.item-body-amount{
  font-size: 12px;
}
.item-body-button{
  font-size: 12px;
  border-radius: 60px;
  opacity: 1;
  background: rgba(136, 136, 136, 0.1);
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #fff;
  padding: 4px 10px;
  cursor: pointer;
}
.item-body-button-active{
  font-size: 12px;
  border-radius: 60px;
  opacity: 1;
  background: rgba(237, 38, 22, 0.1);
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ED2616;
  padding: 4px 10px;
  cursor: pointer;
}
.right-title{
  height: 50px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
}
.right-title-text{

}
.right-title-action{
  color: #ED2616;
}
.select-list{
  height: 560px;
}
.right-foot{
  height: 50px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding-left:10px ;
}
.right-foot-num{
  font-size: 12px;
  margin-top: 10px;
}
.right-foot-amount{
  font-size: 12px;
  margin-top: 10px;
  margin-right: 80px;
}
.right-foot-button{
  width: 80px;
    height: 36px;
    opacity: 1;
    background: #ED2616;
    border-bottom-right-radius: 4px;
    font-size: 14px;
    text-align: center;
    color: #fff;
    line-height: 36px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
}
.select-item{
  margin-left: 10px;
    margin-top: 10px;
}
.select-image{
  width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-left: 10px;
}
.select-image img{
  width: 100px;
    height: 100px;
    border-radius: 10px;
}
.select-image-icon{
  margin-top: 40px;
}
.box-select{
  border: none;
   width:120px;
   outline: none;
}
.box-select::focus{
  border: none;
   width:80px;
}
.box-input{
  border: 1px solid #eee;
   width:50px;
   outline: none;
   text-align: center;
}
.box-input::focus{
  border: none;
   width:80px;
}
</style>
